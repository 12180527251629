.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
	margin-top: 50px;
}

.left_section h1 {
	font-size: 32px;
	font-weight: 300;

	margin-bottom: 10px;

	width: fit-content;

	border-bottom: 1px solid #000;
}

.left_section p {
	font-size: 18px;
	font-weight: 300;

	margin-bottom: 10px;
}

.right_section {
	flex: 1;
}

.sub_title {
	margin-bottom: 48px;
}

.title {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-bottom: 22px;
}

.title h1 {
	font-size: 32px;
	font-weight: 300;
}

.title span {
	font-size: 20px;
	font-weight: 700;

	text-decoration: underline;
}

.field {
	display: flex;
	flex-direction: row;
	gap: 32px;
}

.field_title {
	font-size: 16px;
	display: block;
	width: 100px;
	min-width: 100px;
	text-wrap: nowrap;
}

.bold {
	font-weight: 700;
}

.field_content {
	flex: 1;

	display: flex;
	flex-direction: column;
	gap: 10px;
}

.content_30 {
	width: 30%;
}

.content_70 {
	width: 70%;
}

.content_50 {
	width: 50%;
}

.content_100 {
	width: 100%;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}

.divider {
	width: 100%;
	height: 1px;
	border-top: 1px dashed #111;

	margin-block: 20px;
}

.base_info {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.control_button {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 24px;
	font-weight: 700;
	background-color: #000;
	color: #fff;

	width: 24px;
	height: 24px;
}

.added_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	row-gap: 8px;
}

.added_row_input {
	width: fit-content;
	margin-right: 8px;
	border: 1px solid #000;
	padding: 4px 8px;
	cursor: pointer;
}

.date_separator {
	font-size: 24px;
}

.time_separator {
	text-wrap: nowrap;
}

.time_input {
	width: 100px;
	margin-right: 10px;
}

.checkbox_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}

.text {
	font-size: 14px;
}

.target_checkbox {
	width: 150px;
}

.wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.poster {
	width: 100%;
	aspect-ratio: 32 / 40;
	margin-bottom: 24px;
}

.thumbnail {
	width: 100%;
	aspect-ratio: 1 / 1;
	margin-bottom: 24px;
}

.attach_file {
	width: 100%;
	display: flex;
	gap: 8px;
	align-items: center;
}

.attach_file div {
	width: 100%;
}

.image_title {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 22px;
}

.image_description {
	display: block;
	font-size: 14px;
	font-weight: 500;

	margin-bottom: 24px;
}

.image_container {
	margin-top: 300px;
}

.description_control_button_row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-block: 18px;
}

.description_control_button {
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 8px;
}

.description_title {
	font-size: 16px;
	margin-bottom: 16px;
	display: flex;
	align-items: end;

	gap: 36px;
}

.description_select {
	margin-bottom: 16px;
}

.description_content {
}

.attach_file_list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
}

.footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.footer_fake {
	width: 200px;
}

.footer_progress {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	gap: 4px;
}

.search_row {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.search_row input {
	flex: 1;
}

.list_table_body {
	display: block;
	width: 100%;
	max-height: 220px;
	overflow-y: auto;
}

.search_button {
	background-color: #777;
	color: #fff;
	border-radius: 5px;
	font-size: 12px;
	padding: 6px 8px;
}

.no {
	width: 50px;
}

.name {
	width: 200px;
}

.date {
	width: 100px;
}

.valid {
	width: 100px;
}

.table_subtitle {
	font-size: 18px;
	font-weight: 500;
	margin-block: 8px;
}

.modal_footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 24px;
}
