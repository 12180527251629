.admin_layout {
	padding-top: 40px;

	width: 100%;
	max-width: 1100px;

	margin: 0 auto;
}

.admin_layout_inner {
	width: 100%;

	padding-top: 46px;
	padding-bottom: 68px;
}
