.modal_add {
	padding-inline: 0px;
}

.modal_add_description {
	padding-inline: 90px;
}

.button_container {
	display: flex;
	justify-content: center;
	margin-top: 24px;
}

.divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #111;

	margin-block: 24px;
}

.subtitle_row {
	display: flex;
	align-items: center;
	gap: 60px;
	text-wrap: nowrap;
}

.subtitle {
	font-size: 20px;
	font-weight: 500;
}

.subtitle_description {
	font-size: 14px;
	color: #888;
}

.search_container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.button {
	background-color: #777;
	color: #fff;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 500;
	text-wrap: nowrap;
	padding-inline: 10px;
	padding-block: 5px;
}

.add_item {
	font-size: 20px;
	font-weight: 700;
	text-decoration: underline;
	cursor: pointer;
	text-wrap: nowrap;
}

.table thead tr {
	border-bottom: 1px dashed #111;
}

.table thead tr td {
	font-size: 12px;

	color: #aaa;
}

.table tbody tr td {
	font-size: 14px;
	padding-block: 4px;
}

.table_header {
	padding-right: 12px;
}

.checkbox {
	width: 20px;
}

.no {
	width: 10px;
	text-align: center;
}

.type {
	width: 50px;
	text-align: left;
	padding-left: 12px;
}

.category {
	width: 40px;
	text-align: left;
}

.description {
	width: 150px;
	text-align: left;
}

.table_body {
	display: block;
	max-height: 350px;
	overflow-y: auto;
}

.table_footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;
}

.remove_button {
	background-color: #777;
	color: #fff;
	border-radius: 5px;
	font-size: 18px;
	font-weight: 500;
	padding-inline: 12px;
	padding-block: 8px;
}

.fake {
	width: 100px;
}

.add_type_item {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-inline: 90px;
	padding-block: 20px;
}

.add_type_item_selected {
	background-color: #e9e9e9;
}

.add_type_item input {
	height: 24px;
}

.field_row {
	display: flex;
	align-items: center;
	gap: 10px;
}

.field_label {
	width: 100px;
	font-size: 16px;
}

.field_value {
	width: 500px;
	display: flex;
	align-items: center;
	gap: 6px;
}

.field_title {
	font-size: 18px;
	font-weight: 700;
}

.field_description {
	font-size: 14px;
	color: #888;
}

.select_button {
	display: block;
	min-width: 14px;
	min-height: 14px;
	aspect-ratio: 1;

	border: 2px solid #999;
	border-radius: 50%;
}

.select_button_selected {
	background-color: #111;
	border-color: #111;
}

.detail_add_input {
	width: 100px;
}

.detail_add_button {
	background-color: #000;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 20px;
	min-height: 20px;
}

.tags {
	display: flex;
	align-items: center;
	gap: 20px;

	margin-left: 20px;
}

.tag {
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
}

.input_description {
	font-size: 14px;
	color: #333;
}

.item_divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #111;
}
