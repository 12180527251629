.search_box {
	display: flex;

	margin-top: 36px;
	margin-bottom: 8px;
}

.filter_select {
	width: 190px;

	height: 30px;

	margin-right: 12px;
}

.filter_search_input {
	width: 400px;

	margin-right: 12px;
}

.search_button {
	width: 75px;
	height: 30px;

	font-size: 12px;
	background-color: #777;

	margin-right: 12px;
}

.save_button {
	display: flex;
	align-items: center;
	gap: 4px;
	background-color: transparent;

	font-size: 12px;
	font-weight: 500;

	text-decoration: underline;

	margin-right: 30px;
}

.save_button_icon {
	width: 34px;
	height: 34px;
}

.register_button {
	font-size: 21px;
	font-weight: 700;

	background-color: transparent;

	text-decoration: underline;
}
