.table_body tr:first-child td {
	font-weight: 700;
}

.table_body tr:nth-child(2) td {
	font-weight: 700;
}

.table_body tr:nth-child(3) td {
	font-weight: 700;
}

.table_row td {
	font-weight: 300;
	font-size: 14px;
}

.table_footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	margin-top: 24px;
}

.check_box {
	width: 32px;
}

.number {
	width: 60px;
}

.title {
	width: 300px;
	text-align: left;
}

.date {
	width: 180px;
}

.location {
	width: 240px;
}

.status {
	width: 80px;
}

.participants {
	width: 80px;
}
