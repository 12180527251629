.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	padding: 60px 80px;
	border-radius: 10px;
}

.close_button {
	position: absolute;
	top: 24px;
	right: 24px;

	background-color: transparent;
}

.modal_title {
	width: 100%;
	text-align: center;

	font-size: 24px;
	font-weight: 500;
	text-decoration: underline;

	margin-bottom: 24px;
}

.modal_description {
	font-size: 12px;
	color: #888;
	margin-bottom: 8px;
}
