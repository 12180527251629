.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
}

.default_thumbnail {
	aspect-ratio: 1/1;
	margin-bottom: 24px;
}

.file_input_container {
	display: flex;
	flex-direction: row;

	cursor: pointer;
	gap: 4px;
}

.file_input {
	flex: 1;
	height: 28px;
	border: 1px solid #aaa;
}

.file_input_button {
	width: fit-content;
	height: 28px;
	background-color: #777;
	padding-inline: 10px;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
}

.right_section {
	flex: 1;
}

.title_container {
	display: flex;
	justify-content: space-between;
}

.title {
	font-size: 32px;
	font-weight: 500;
}

.description {
	font-size: 14px;

	padding-bottom: 22px;
}

.confirm {
	display: flex;
	flex-direction: column;
	gap: 24px;

	padding-block: 24px;

	border-bottom: 1px dashed #aaa;
}

.confirm_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.confirm_label {
	font-weight: 700;
	font-size: 14px;
	width: 100px;
}

.confirm_value {
	flex: 1;
	font-size: 16px;
}

.divided_confirm_row {
	display: flex;
	flex-direction: row;
	gap: 40px;
}

.divided_confirm_row_left {
	width: 40%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.divided_confirm_row_right {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.address_input_container {
	display: flex;
	flex-direction: row;

	cursor: pointer;
	gap: 4px;
}

.address_input {
	flex: 1;
	height: 28px;
	border: 1px solid #aaa;
}

.address_input_button {
	width: fit-content;
	height: 28px;
	background-color: #777;
	padding-inline: 10px;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
}

.confirm_footer {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	padding-block: 32px;
}

.submit_button {
	padding-inline: 64px !important;
}

.edit_button {
	background-color: #777;
	color: #fff;
}

.address {
	display: flex;
	flex-direction: row;
	gap: 40px;
}

.caution {
	font-size: 14px;
	margin-left: 28px;
}

.register_button {
	background-color: transparent;
	font-size: 20px;
	font-weight: 700;
	text-decoration: underline;
	margin-left: 80px;
}

.table_body {
	font-weight: 300;
}

.table_body tr:first-child td {
	font-weight: 700;
}

.table_body tr:nth-child(2) td {
	font-weight: 700;
}

.table_body tr:nth-child(3) td {
	font-weight: 700;
}

.number {
	width: 5%;
}

.name {
	width: 40%;
	text-align: left;
}

.status {
	width: 10%;
}

.location {
	width: 15%;
}

.date_range {
	width: 20%;
}

.date {
	width: 10%;
}

.table_footer {
	display: flex;
	justify-content: center;
	margin-top: 24px;
}
