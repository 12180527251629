.modal {
	width: 850px;
}

.search_container {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-inline: 20%;
	gap: 2px;

	margin-block: 10px;
}

.search_button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 28px;

	background-color: #777;
	color: #fff;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 500;
}

.checkbox {
	width: 40px;
}

.no {
	width: 40px;
}

.tournament_name {
	width: 300px;
	text-align: left;
}

.event_count {
	width: 70px;
}

.tournament_period {
	width: 200px;
}

.table {
	width: 700px;
	margin-bottom: 24px;
}

.table td {
	font-size: 14px;
}

.button_container {
	display: flex;
	justify-content: center;
	margin-top: 24px;
}
