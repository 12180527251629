.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
	margin-top: 50px;
}

.left_section h1 {
	font-size: 32px;
	font-weight: 300;

	margin-bottom: 10px;
}

.left_section p {
	font-size: 18px;
	font-weight: 300;

	margin-bottom: 10px;
}

.right_section {
	flex: 1;
}

.back_button_container {
	display: flex;
	flex-direction: row;

	justify-content: end;

	width: 100%;

	margin-bottom: 38px;
}

.back_button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;

	font-size: 14px;
}

.competition_info {
	position: relative;
	width: 100%;

	display: flex;
	flex-direction: column;

	gap: 24px;
}

.competition_views {
	position: absolute;
	right: 0;
	top: 0px;

	font-size: 14px;
}

.competition_info_item {
	display: flex;
	flex-direction: row;
}

.competition_info_type {
	width: 100px;
	font-weight: 400;
	font-size: 16px;
}

.competition_info_value {
	font-size: 16px;
}

.competition_date {
	width: 80px;
	text-align: left;
}

.competition_name {
	width: 500px;
	text-align: left;
}

.competition_people {
	width: 80px;
	text-align: left;
}

.competition_table_footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: 100%;

	margin-top: 20px;
}

.competition_table_footer_button_group {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.blank_div {
	width: 200px;
}
