.container {
	display: flex;
	flex-direction: column;
	border-bottom: 3px solid #111;
}

.upper_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding-block: 32px;
	border-bottom: 1px dashed #111;
}

.title {
	font-size: 20px;
	font-weight: 700;
}

.info {
	display: flex;
	flex-direction: row;
	gap: 4px;
	font-size: 14px;
}

.participants {
	font-weight: 700;
}

.back_button {
	width: 200px;
	display: flex;
	justify-content: start;
	align-items: center;
}

.lower_container {
	display: flex;
	flex-direction: row;
	align-items: center;

	width: 100%;

	gap: 80px;
}

.category_list {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	padding-block: 16px;
}

.category_item {
	font-size: 16px;
}

.current_category {
	font-weight: 700;
	text-decoration: underline;
}
