.admin_header {
	display: flex;
	align-items: center;
}

.admin_header_inner {
	display: flex;
	flex-direction: row;

	width: 100%;

	justify-content: space-between;
}

.admin_header_inner_left {
	display: flex;
	flex-direction: row;

	align-items: center;
}

.logo {
	width: 180px;
	height: 33px;
	object-fit: contain;

	margin-right: 14px;
}

.role_select {
	margin-right: 50px;
}

.link_list {
	display: flex;
	gap: 76px;
}

.link_list a {
	color: #111;
	font-weight: 500;
	font-size: 16px;
}

.admin_header_inner_right {
	display: flex;
	flex-direction: row;

	align-items: center;
}

.admin_profile {
	display: flex;
	flex-direction: row;

	align-items: center;

	gap: 8px;
}

.admin_profile_name {
	font-weight: 300;
	font-size: 14px;

	color: #111;
}

.admin_profile_img {
	width: 30px;
	height: 30px;

	border-radius: 50%;
	object-fit: cover;
}
