.container {
	display: flex;
	gap: 50px;
}

.left_section {
	width: 300px;
}

.right_section {
	flex: 1;
}

.poster {
	width: 100%;
	height: 420px;
	object-fit: cover;

	margin-bottom: 24px;
}

.select_group {
	display: flex;
	flex-direction: row;
	justify-content: end;
	gap: 4px;
	padding-top: 24px;
}

.select_group select {
	width: 100px;
}

.table_head {
	width: 20%;
}

.table_footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
	padding-block: 24px;
}
