.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
	margin-top: 50px;
}

.left_section h1 {
	font-size: 32px;
	font-weight: 300;

	margin-bottom: 10px;

	width: fit-content;

	border-bottom: 1px solid #000;
}

.left_section p {
	font-size: 18px;
	font-weight: 300;

	margin-bottom: 10px;
}

.right_section {
	flex: 1;
}

.sub_title {
	margin-bottom: 48px;
}

.title {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-bottom: 22px;
}

.title h1 {
	font-size: 32px;
	font-weight: 300;
}

.title span {
	font-size: 20px;
	font-weight: 700;

	text-decoration: underline;
	cursor: pointer;
}

.description {
	font-size: 16px;
	font-weight: 400;
}

.sub_title_row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	margin-block: 16px;
	padding-bottom: 16px;
	border-bottom: 1px dashed #111;
}

.sub_title_text {
	font-size: 24px;
	font-weight: 500;
}

.sub_title_action {
	font-size: 22px;
	font-weight: 700;

	text-decoration: underline;
	cursor: pointer;
	text-wrap: nowrap;
}

.field_row {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 16px;
	margin-bottom: 16px;
}

.field {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.field_type {
	width: 100px;
	min-width: 100px;
	display: flex;
	font-size: 16px;
	gap: 8px;
	text-wrap: nowrap;
}

.field_value {
	width: 100%;
	font-size: 14px;
	font-weight: 400;
}

.bold {
	font-weight: 700;
}

.wave_divider {
	font-size: 16px;
}

.divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #111;
	margin-block: 32px;
}

.detail_add_button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.select_item_add_container {
	cursor: pointer;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
	margin-bottom: 32px;

	font-size: 18px;
	font-weight: 500;
}

.select_item_add {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 60px;
	height: 60px;
	background-color: #111;
	border-radius: 50%;
}

.table_header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.table_title {
	font-size: 20px;
	font-weight: 700;
}

.select_group {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.checkbox {
	width: 20px;
}

.no {
	width: 100px;
}

.category {
	width: 100px;
}

.age {
	width: 200px;
}

.rank {
	width: 100px;
}

.applicants {
	width: 100px;
}

.edit {
	width: 100px;
	text-decoration: underline;
	cursor: pointer;
}

.table_body {
	max-height: 300px;
	overflow-y: auto;
	display: block;
}

.select_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 8px;
}

.select {
	max-width: 80px;
	min-width: 80px;
}

.table_footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 24px;
}

.pagination {
	display: flex;
	flex-direction: row;
	gap: 32px;
}

.pagination_button {
	font-size: 18px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2px;
}

.footer_progress {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	gap: 4px;
}
