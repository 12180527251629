.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
	margin-top: 50px;
}

.left_section h1 {
	font-size: 32px;
	font-weight: 300;

	margin-bottom: 10px;

	width: fit-content;

	border-bottom: 1px solid #000;
}

.left_section p {
	font-size: 18px;
	font-weight: 300;

	margin-bottom: 10px;
}

.right_section {
	flex: 1;
}

.sub_title {
	margin-bottom: 48px;
}

.title {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-bottom: 22px;
}

.title h1 {
	font-size: 32px;
	font-weight: 300;
}

.title span {
	font-size: 20px;
	font-weight: 700;

	text-decoration: underline;
	cursor: pointer;
}

.description {
	font-size: 16px;
	font-weight: 400;
}

.sub_title_row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	margin-block: 16px;
	padding-bottom: 16px;
	border-bottom: 1px dashed #111;
}

.sub_title_text {
	font-size: 24px;
	font-weight: 500;
}

.divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #111;
	margin-block: 32px;
}

.prize_type_container {
	display: flex;
	flex-direction: row;
	gap: 8px;

	margin-bottom: 12px;
}

.prize_type_container select {
	height: 28px;
}

.prize_file_description {
	font-size: 14px;
	font-weight: 400;
	color: #333;

	margin-bottom: 24px;
}

.prize_file_input {
	flex: 1;
}

.label {
	font-size: 16px;
	font-weight: 500;
	width: 100px;
}

.thumbnail {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.banner_row {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.banner_row_item {
	aspect-ratio: 1 / 1;

	width: 100%;

	display: flex;
	flex-direction: column;
	gap: 8px;
}

.default_thumbnail {
	width: 100%;
	height: 100%;

	background-color: #f0f0f0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.table_footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 24px;
}

.pagination {
	display: flex;
	flex-direction: row;
	gap: 32px;
}

.pagination_button {
	font-size: 18px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2px;
}

.footer_progress {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	gap: 4px;
}
