.terms h2 {
	font-weight: 700;
	font-size: 16px;
	letter-spacing: -0.02em;
	line-height: 24px;
}

.terms h3 {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: -0.02em;
	line-height: 21px;
}

.terms p {
	font-size: 14px;
	letter-spacing: -0.02em;
	line-height: 21px;
}

.terms p strong {
	font-weight: 700;
}

.box {
	width: 660px;
	margin-inline: auto;
}

.divider {
	width: 100%;

	margin-block: 32px;

	border-bottom: 1px dashed #000;
}

.agree_box {
	display: flex;
	justify-content: flex-end;
	width: 100%;

	margin-top: 24px;

	gap: 16px;
}

.agree_box label {
	display: flex;
	align-items: center;
	gap: 5px;

	font-size: 16px;
	font-weight: 700;
}

.complete_button {
	width: 320px;

	font-size: 24px;

	margin-inline: auto;
}

.complete_button svg {
	margin-right: 8px;
}

@media (max-width: 768px) {
	.box {
		width: 100%;
		padding-inline: 20px;
	}

	.agree_box {
		justify-content: center;
	}
}
