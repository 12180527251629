.button_group {
	width: 100%;
	display: flex;
	gap: 4px;
	height: 38px;
}

.apply_button {
	display: flex;
	align-items: center;

	flex: 1;
	height: 100%;
	background-color: #111;
	border-radius: 5px;
	padding-left: 8px;
	color: #fff;
	font-size: 20px;
}

.like_button {
	width: 38px;
	height: 100%;
	background-color: #888;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.like_button_active {
	background-color: #e34f4f;
}
