.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding-block: 200px;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	gap: 26px;
}

.text_wrapper {
	display: flex;
	flex-direction: column;

	color: #333;
}

.text_wrapper br {
	display: none;
}

.title {
	font-size: 71px;
	font-weight: 100;
	letter-spacing: -0.05em;

	margin-bottom: 4px;
}

.description {
	font-size: 24px;
	font-weight: 500;
	letter-spacing: -0.05em;
}

.description_detail {
	font-size: 16px;
	letter-spacing: -0.05em;
}

.button_container {
	display: flex;
	gap: 10px;

	width: 100%;
}

.button_container button {
	position: relative;
	width: 50%;
}

.arrow_right {
	display: none;
}

.button_icon {
	display: none;
}

@media (max-width: 768px) {
	.container {
		padding-inline: 50px;
		padding-block: 140px;
	}

	.text_wrapper {
		text-align: center;
	}

	.text_wrapper br {
		display: block;
	}

	.title {
		font-size: 37px;
		margin-bottom: 16px;
	}

	.description {
		font-size: 18px;
		margin-bottom: 16px;
	}

	.description_detail {
		font-size: 14px;
	}

	.button_container {
		flex-direction: column;
		gap: 16px;
	}

	.button_container button {
		width: 100%;
	}
	.line_space {
		display: block;
	}

	.button_icon {
		display: block;
	}
}
