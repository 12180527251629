.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
	margin-top: 50px;
}

.left_section h1 {
	font-size: 32px;
	font-weight: 300;

	margin-bottom: 10px;
}

.left_section p {
	font-size: 18px;
	font-weight: 300;

	margin-bottom: 10px;
}

.right_section {
	flex: 1;
}

.back_button_container {
	display: flex;
	flex-direction: row;

	justify-content: end;

	width: 100%;

	margin-bottom: 38px;
}

.back_button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;

	font-size: 14px;
}

.input_list {
	display: flex;
	flex-direction: column;

	gap: 15px;

	margin-bottom: 18px;
}

.input_row {
	display: flex;
	flex-direction: row;

	gap: 26px;
}

.input_type {
	width: 110px;

	font-size: 16px;
	font-weight: 400;
}

.input_value {
	width: 100%;
}

.history_header {
	font-size: 16px;
	font-weight: 400;
}

.history_title {
	width: 500px;
	text-align: left;
}

.history_date {
	text-align: left;

	width: 120px;
}

.history_date_cell {
	display: flex;

	width: 120px;
}

.history_date_year {
	min-width: 70px !important;
}

.history_date_month {
	min-width: 50px !important;
	margin: 0px;
}

.history_title_input {
	width: 470px;
}

.history_applicants {
	width: 120px;
	text-align: left;
}

.history_applicants_cell {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.history_delete_button {
	width: 28px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: black;
	font-weight: 700;
	color: white;
}

.register_button_container {
	display: flex;
	flex-direction: row;
	justify-content: center;

	margin-top: 40px;
}

.register_button {
	width: 140px;
	height: 140px;

	background-color: white;
	color: black;

	border: 5px solid black;
	border-radius: 50%;

	font-size: 28px;
	font-weight: 700;
}
