.table {
	border-bottom: 1px dashed #111;
}

.table_scrollable {
	overflow-x: auto;
}

.scrollable_table {
	width: fit-content;
	min-width: 100%;
}

.table_head {
	padding-block: 20px;
	text-align: center;

	font-size: 12px;
	font-weight: 400;
	color: #aaa;

	border-bottom: 1px dashed #111;
}

.table_body {
	padding-block: 12px;
}

.table_row {
	display: table;
	width: 100%;
}

.table_cell {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	padding-block: 8px;
}

.table_cell_left {
	text-align: left;
}

.table_cell_center {
	text-align: center;
}

.table_cell_right {
	text-align: right;
}
