.container {
	display: flex;
	gap: 50px;
}

.left_section {
	width: 300px;
}

.right_section {
	flex: 1;
}

.poster {
	width: 100%;
	height: 420px;
	object-fit: cover;

	margin-bottom: 24px;
}

.thumbnail_title {
	font-size: 16px;
	font-weight: 700;
	margin-top: 28px;
	margin-bottom: 20px;
}

.thumbnail {
	width: 100%;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	object-position: 0% 0%;

	border-radius: 4px;
}

.info {
	display: flex;
	flex-direction: column;
	gap: 18px;
	padding-block: 24px;
	border-bottom: 1px dashed #111;
}

.info_row {
	display: flex;
	flex-direction: row;
	font-size: 14px;
}

.info_type {
	width: 100px;
}

.info_content {
	flex: 1;
}

.settlement {
	display: flex;
	align-items: center;
	gap: 24px;

	padding-block: 24px;

	border-bottom: 1px dashed #111;
}

.settlement_title {
	font-size: 16px;
	font-weight: 700;
}

.settlement_content {
	font-size: 14px;
}

.content {
	padding-block: 24px;
	border-bottom: 1px dashed #111;
}

.content h1 {
	font-size: 16px;
	font-weight: 700;
}

.content p {
	font-size: 14px;
}

.content br {
	display: block;
	height: 16px;
}

.attach_file {
	padding-block: 16px;
	border-bottom: 1px dashed #111;

	font-size: 14px;
	font-weight: 700;
}

.info_footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	padding-block: 24px;
}
