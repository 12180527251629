/* admin_layout css */
.admin_layout {display: flex;}
.admin_main_content {margin-left: 240px; padding-top: 60px; width: calc(100% - 240px); background-color: #f9f9f9; min-height: 100vh;}
.admin_area {padding: 20px;}

/* 페이지명 css */
.admin_title  {font-size: 24px; font-weight: bold; margin-bottom: 20px; color: #333; text-align: center}

/* admin_list 공통 css */
.admin_list {padding: 20px; background-color: #ffffff; margin: 20px auto; border-radius: 8px;}

/* 상단 바 */
.admin_bar {display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px; padding: 10px; border-radius: 6px;}

/* Total CNT */
.admin_total {font-size: 16px; color: #555;}
.admin_total b {font-weight: bold; color: #000;}

/* 검색 박스 */
.admin_search {display: flex; gap: 10px; align-items: center;}
.admin_search select, .admin_search input, .admin_search button {padding: 8px 14px; font-size: 14px; border: 1px solid #ddd; border-radius: 4px; background-color: #f9f9f9; color: #333; box-sizing: border-box;}
.admin_search button {background-color: #555; color: #fff; border: none; cursor: pointer; transition: background-color 0.2s ease-in-out;}
.admin_search button:hover {background-color: #333;}

/* 목록화면 버튼 스타일 */
.admin_table_btn {display: flex; justify-content: flex-end; gap: 10px; margin-top: 20px;}
.admin_detail_btn, .admin_back_btn, .admin_update_btn, .admin_insert_btn {padding: 10px 20px; font-size: 14px; border: none; border-radius: 4px; cursor: pointer; transition: background-color 0.3s ease;}
.admin_detail_btn, .admin_back_btn, .admin_update_btn, .admin_insert_btn {background-color: #555; color: white;}
.admin_detail_btn:hover, .admin_update_btn:hover, .admin_insert_btn:hover {background-color: #333;}

/* 테이블 스타일 */
.admin_table {margin-top: 15px; overflow-x: auto;}
.admin_table table {width: 100%; border-collapse: collapse; table-layout: auto;}
.admin_table th {font-size: 16px; font-weight: bold; background-color: #f1f1f1; color: #333; padding: 14px; text-align: left;}
.admin_table td {font-size: 15px; color: #333; padding: 12px; white-space: nowrap; background-color: #fff}
.admin_table tr:ntr-child(even) td {background-color: #f9f9f9;}
.admin_table tr:hover td {background-color: #eee;}
.admin_table input[type="checkbox"] {width: 16px; height: 16px; margin: 0; cursor: pointer; border: 2px solid #ddd; border-radius: 4px; background-color: white; appearance: none;}
.admin_table input[type="checkbox"]:checked {background-color: #555; border-color: #555; box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);}
.amdin_table input[type="checkbox"]:checked {border-color: #333; background-color: #f1f1f1};
.admin_table tr:hover td {background-color: #f1f1f1;}

/* 반응형 처리 */
@media (max-width: 768px) {
  .bar {flex-direction: column; gap: 10px;}
  .admin_search {flex-wrap: wrap; gap: 5px;}
  .admin_search select, .admin_search input, .admin_search button { width: 100%;}
  .admin_table th, .admin_td {font-size: 14px; padding: 10px;}
}


/* 사용자 상세 css */
.admin_detail_contents {max-width: 650px; margin: 0 auto; transition: all 0.4s ease-in-out; -webkit-transition: all 0.4s ease-in-out;}
.admin_user_pro_info, .admin_user_info, .admin_form_event, .admin_user_auth_list {padding: 20px; margin: 20px 0; background-color: #ffffff; border: 1px solid #ddd; border-radius: 8px;}
.checkRedu {padding: 8px 12px; background-color: #555; color: white; border-radius: 4px; cursor: pointer; font-size: 14px; transition: background-color 0.3s ease;}
.admin_user_pro {display: flex; flex-direction: column; align-items: center;}
.admin_user_pro .admin_form {display: flex; align-items: center; gap: 10px; margin-bottom: 15px;}
.admin_user_pro .admin_form input[type="text"] {flex: 1;}
.admin_user_pro .admin_form span {font-weight: bold; margin-right: 5px;}
.admin_user_pro .admin_form input[type="radio"] {margin-left: 5px;}
.admin_user_pro .address-form input[type="text"] {flex: 1;}
.address_form .address_label {padding: 8px 12px; background-color: #555; color: white; border-radius: 4px; cursor: pointer; font-size: 14px; transition: background-color 0.3s ease;}
.amdin_user_photo {text-align: center; border: 1px dashed #ddd; border-radius: 8px; padding: 15px; background-color: #f9f9f9;}
.admin_user_photo img {display: block; width: 100%; max-width: 200px; height: auto; border-radius: 8px; background-color: #eee; object-fit: cover; margin: 0 auto;}
.admin_user_photo dd {margin-top: 10px; font-size: 14px; line-height: 1.6; color: #555;}
.admin_user_photo b {font-weight: bold;}
.admin_profile_btn {margin-top: 10px; padding: 8px 12px; background-color: #555; color: #fff; font-size: 14px; border-radius: 4px; cursor: pointer; text-align: center; transition: background-color 0.2;}
.admin_profile_btn:hover {background-color: #333;}
input[type="file"] {display: none;}
.admin_form p {margin-bottom: 5px; font-size: 14px; font-weight: bold; color: #333;}
.admin_form input[type="text"], .admin_form input[type="date"] {width: 100%; padding: 10px 12px; font-size: 14px; border: 1px solid #ddd; border-radius: 4px; background-color: #f9f9f9; color: #333; margin-bottom: 15px; box-sizing: border-box;}
.admin_form input[type="readonly"] {background-color: #eee; color: #888; cursor: not-allowed;}
.admin_form input[type="radio"] {appearance: none; width: 20px; height: 20px; border: 2px solid #ddd; border-radius: 50%; outline: none; cursor: pointer; transition: 0.2s; background-color: #fff;}
.admin_form input[type="radio"]:checked {background-color: #555; border-color: #555; box-shadow: 0 0 5px rgba(0,0,0,0.3);}
.admin_form label {margin-left: 10px; font-size: 14px; color: #555; cursor: pointer;}
/* 관심 지역 및 종목 */
.admin_form_event, .admin_sub_title {font-size: 18px; font-weight: bold; color: #333; margin-bottom: 10px;}
.admin_area, .admin_event {margin-top: 15px; padding: 10px; border: 1px solid #ddd; border-radius: 4px; background-color: #f9f9f9;}
.admin_form_event .admin_area .area {font-size: 1.8rem; font-weight: 700; letter-spacing: -0.05em; line-height: 1.2; display: flex; align-items: center; justify-content: flex-start;}
.admin_form_event .admin_area .area select {margin-left: 5px;}
.admin_form_event .admin_area .admin_event {margin-top: 30px;}
.admin_form_event .admin_area .admin_event > p {font-size: 1.8rem; font-weight: 700; letter-spacing: -0.05em; line-height: 1.2;}
.admin_form_event .admin_area .admin_event > div {display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; margin-top: 30px; max-width: 650px;}
.admin_form_event .admin_area .admin_event > div button {width: 60px; height: 60px; text-align: center; padding: 0; background: #f1f1f1; border-radius: 5px; font-size: 1.4rem; letter-spacing: 0.05em; font-weight: 500; line-height: 1.4; margin: 0 5px 5px 0; color: #111;}
.admin_form_event .admin_area .admin_event > div button.active {background: #111; color: #fff;}
.admin_form_event .admin_area .admin_event > div button p {font-size: 2.0rem; font-weight: 700;}
.admin_detail_buttons {display: flex; justify-content: space-between; align-items: center; margin-top: 20px; padding: 10px 20px; background-color: #f9f9f9;}
.admin_detail_buttons .btn {padding: 10px 20px; font-size: 14px; border: none; border-radius: 4px; cursor: pointer; transition: background-color 0.3s ease;}
.admin_detail_buttons .btn.back {background-color: #ddd; color: #333;}
.admin_detail_buttons .btn.update {background-color: #ddd; color: #333;}
.admin_detail_buttons .btn:hover {background-color: #333; color: #fff;}
@media (max-widt: 768px) {
  .admin_user_pro_info, .admin_user_info, .admin_form_event, .admin_user_auth_list {padding: 15px;}
  .admin_form input[type="text"], .admin_form input[type="date"] {font-size: 12px; padding: 8px 10pxl}
  .admin_form label {font-size: 12px;}
  .admin_area, .admin_event {padding: 8px;}
}
/* 체육관 css */
.admin_gym_detail_container {display: flex; justify-content: space-between; align-items: flex-start; gap: 20px;}
.admin_gym_image {flex: 1; max-width: 300px;}
.admin_gym_image img {width: 100%; height: auto; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);}
.admin_gym_info {flex: 2; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 10px;}
.admin_gym_group {margin-bottom: 15px;}
.admin_gym_group label {display: block; font-weight: 5px; color: #333}
.admin_gym_group input {width: 100%; padding: 10px; border: 1px solid #ddd; border-radius: 5px; font-size: 14px; background-color: #f9f9f9;}