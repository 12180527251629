.table_row td {
	font-size: 14px;
}

.table_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	margin-top: 38px;
}

.table_header_right {
	display: flex;
	justify-content: space-between;
	align-items: center;

	gap: 6px;
}

.excel_download {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 150px;

	text-decoration: underline;
	font-weight: 500;
	font-size: 14px;
	height: 50px;
	width: 200px;
	gap: 4px;
	text-wrap: nowrap;
}

.total_count {
	font-weight: 700;
	font-size: 16px;
	text-wrap: nowrap;
}

.check_box {
	width: 5%;
}

.number {
	width: 5%;
}

.name {
	width: 10%;
}

.phone {
	width: 10%;
}

.email {
	width: 30%;
}

.category {
	width: 10%;
}

.status {
	width: 10%;
}

.date {
	width: 180px;
}

.table_footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	margin-top: 24px;
}
