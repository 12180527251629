.content {
	display: flex;
	flex-direction: column;
	gap: 48px;

	width: 100%;
}

.title_box {
	display: flex;
	flex-direction: row;
	align-items: center;

	gap: 6px;
}

.action_button {
	color: #333;
	font-size: 14px;
	font-weight: 500;
	background-color: transparent;
}

.select_box {
	border-radius: 5px;
}

.title {
	font-weight: 700;
	font-size: 20px;

	color: #333;
}

.competition_detail {
	width: 100%;
	height: 450px;

	display: flex;
}

.competition_thumbnail {
	width: 320px;
	height: 100%;

	object-fit: cover;
}

.competition_info {
	flex: 1;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	padding: 30px 50px;

	border-bottom: 1px dashed #111;
}

.competition_info_title_box {
	display: flex;
	flex-direction: row;
	align-items: center;

	gap: 6px;

	margin-bottom: 24px;
}

.competition_info_title {
	font-size: 20px;
	font-weight: 500;
}

.competition_info_list {
	display: flex;
	flex-direction: column;

	gap: 16px;
}

.competition_info_item_box {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.competition_info_item_key {
	color: #111;
	font-size: 16px;
	font-weight: 700;
}

.competition_info_item_value {
	color: #111;
	font-size: 16px;
	font-weight: 400;
}

.disciplines_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.disciplines_title_box {
	display: flex;
	gap: 6px;
}

.disciplines_select_list {
	display: flex;
	gap: 4px;
}

.disciplines_table_body {
	display: block;
	max-height: 220px;
	overflow-y: auto;
}

.disciplines_no_head {
	width: 5%;
}

.disciplines_group_head {
	width: 10%;
}

.disciplines_age_head {
	width: 15%;
}

.disciplines_grade_head {
	width: 25%;
}

.disciplines_applicants_head {
	width: 15%;
}

.entry_table_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.entry_table_title_box {
	display: flex;
	gap: 6px;
}

.entry_table_select_list {
	display: flex;
	gap: 4px;
}

.entry_table_table_body {
	display: block;
	max-height: 400px;
	overflow-y: auto;
}

.entry_table_no_head {
	width: 5%;
}

.entry_table_name_head {
	width: 10%;
}

.entry_table_phone_head {
	width: 15%;
}

.entry_table_email_head {
	width: 25%;
}

.entry_table_details_head {
	width: 15%;
}

.entry_table_status_head {
	width: 10%;
}

.entry_table_date_head {
	width: 20%;
}

.table_row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 64px;
}

.simple_table {
	width: 100%;
}

.simple_table_box {
	position: relative;
}

.simple_table_row {
	height: 30px;
}

.simple_table_row td {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
}

.simple_table_title {
	width: 100%;

	font-size: 14px;
	font-weight: 500;
}

.simple_table_date {
	width: 90px;

	font-size: 14px;
	color: #aaa;
}

.simple_table_views {
	width: 60px;

	font-size: 14px;
	color: #333;
}

.simple_table_date_head {
	color: #aaa;
	font-size: 12px;

	position: absolute;
	right: 100px;
	top: 0px;
}

.simple_table_views_head {
	color: #aaa;
	font-size: 12px;

	position: absolute;
	right: 35px;
	top: 0px;
}
