.radio {
	display: block;

	width: 14px !important;
	height: 14px !important;

	border: 2px solid #999;

	border-radius: 50%;

	margin: 0 !important;
	padding: 0 !important;

	cursor: pointer;
}
