.container {
	display: flex;
	gap: 50px;
}

.left_section {
	width: 300px;
}

.right_section {
	flex: 1;
}

.poster {
	width: 100%;
	height: 420px;
	object-fit: cover;

	margin-bottom: 24px;
}

.search_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-top: 32px;
}

.search {
	width: 500px;
	display: flex;
	flex-direction: row;

	gap: 4px;
}

.search input {
	width: 100%;
}

.search button {
	width: 100px;
	height: 30px;
	font-size: 14px;
}

.register_button {
	font-size: 21px;
	font-weight: 700;
	text-decoration: underline;
}

.check_box {
	width: 24px;
}

.title {
	width: 500px;
	text-align: left;
	padding-left: 16px;
}

.date {
	width: 100px;
}

.views {
	width: 30px;
}

.notice_content_text {
	width: 100%;
}

.notice_content_text img {
	width: 100%;
	margin-block: 16px;
}

.notice_content_text p {
	font-size: 16px;
}

.notice_content_text strong {
	font-size: 20px;
}
