.back_button_container {
	display: flex;
	flex-direction: row;

	justify-content: end;

	width: 100%;

	margin-bottom: 38px;
}

.back_button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	background-color: transparent;
}
