.file_input_container {
	position: relative;
	display: flex;
	flex-direction: row;

	cursor: pointer;
	gap: 4px;
}

.file_input {
	flex: 1;
	height: 28px;
	border: 1px solid #aaa;

	display: flex;
	align-items: center;
}

.attach_file_container {
	width: 100%;
}

.file_input_button {
	width: fit-content;
	height: 28px;
	background-color: #777;
	padding-inline: 10px;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
}

.real_input {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
