.container {
	display: flex;
	gap: 50px;
}

.left_section {
	width: 300px;
}

.right_section {
	flex: 1;
}

.poster {
	width: 100%;
	height: 420px;
	object-fit: cover;

	margin-bottom: 24px;
}

.result_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-top: 32px;
	margin-bottom: 24px;
}

.result_count {
	font-size: 16px;
	font-weight: 700;
}

.search {
	width: 500px;
	display: flex;
	flex-direction: row;

	gap: 4px;
}

.register_button {
	font-size: 21px;
	font-weight: 700;
	text-decoration: underline;
}

.qna_container {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.qna_question {
	font-size: 14px;
	margin-bottom: 24px;
}

.qna_answer {
	border: 1px solid #aaa;
	padding: 17px;
}

.qna_answer * {
	font-size: 14px;
}

.qna_answer_date {
	font-size: 14px;
	margin-top: 16px;
	font-weight: 700;
}

.table_footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	margin-top: 24px;
}

.pagination_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 32px;
}

.pagination_number {
	display: flex;
	flex-direction: row;
	font-size: 16px;
}

.divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #aaa;

	margin-block-start: 24px;
}
