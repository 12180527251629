.container {
	display: flex;
	gap: 50px;
}

.left_section {
	width: 300px;
}

.right_section {
	flex: 1;
}

.poster {
	width: 100%;
	height: 420px;
	object-fit: cover;

	margin-bottom: 24px;
}

.result_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-top: 32px;
}

.result_count {
	font-size: 16px;
	font-weight: 700;
}

.search {
	width: 500px;
	display: flex;
	flex-direction: row;

	gap: 4px;
}

.register_button {
	font-size: 21px;
	font-weight: 700;
	text-decoration: underline;
}

.table td {
	font-size: 14px;
}

.no {
	width: 20px;
}

.content {
	max-width: 370px;
	padding-left: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.nickname {
	width: 100px;
}

.date {
	width: 100px;
}

.status {
	width: 60px;
}

.table_footer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 38px;
}
