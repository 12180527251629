.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pagination p {
	display: flex;

	cursor: pointer;

	justify-content: center;
	align-items: center;

	width: 34px;
	height: 34px;

	font-size: 16px;
	font-weight: 300;
}

.current_page {
	font-weight: 700 !important;
	text-decoration: underline;
}
