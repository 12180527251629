.step_list {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.step_item {
	font-size: 20px;
	font-weight: 300;
}

.current_step_item {
	font-weight: 700 !important;
	text-decoration: underline;
}
