.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
}

.default_thumbnail {
	aspect-ratio: 1/1;
	margin-bottom: 24px;
}

.file_input_container {
	display: flex;
	flex-direction: row;

	cursor: pointer;
	gap: 4px;
}

.file_input {
	flex: 1;
	height: 28px;
	border: 1px solid #aaa;
}

.file_input_button {
	width: fit-content;
	height: 28px;
	background-color: #777;
	padding-inline: 10px;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
}

.right_section {
	flex: 1;
}

.title {
	font-size: 32px;
	font-weight: 300;
	margin-bottom: 22px;
}

.description {
	font-size: 14px;

	padding-bottom: 22px;
}

.confirm {
	display: flex;
	flex-direction: column;
	gap: 24px;

	padding-block: 24px;
	border-block: 1px dashed #aaa;
}

.confirm_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.confirm_label {
	font-weight: 700;
	font-size: 14px;
	width: 100px;
}

.confirm_value {
	flex: 1;
	font-size: 16px;
}

.divided_confirm_row {
	display: flex;
	flex-direction: row;
	gap: 40px;
}

.divided_confirm_row_left {
	width: 35%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.divided_confirm_row_right {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.address_input_container {
	display: flex;
	flex-direction: row;

	cursor: pointer;
	gap: 4px;
}

.address_input {
	flex: 1;
	height: 28px;
	border: 1px solid #aaa;
}

.address_input_button {
	width: fit-content;
	height: 28px;
	background-color: #777;
	padding-inline: 10px;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
}

.multiple_select {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.multiple_select select {
	flex: 1;
}

.plus_button {
	width: 24px;
	height: 24px;
	background-color: #000;
	color: #fff;
}

.confirm_footer {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-block: 32px;
}

.submit_button {
	padding-inline: 64px !important;
}

.edit_button {
	background-color: #777;
	color: #fff;
}

.button_group {
	display: flex;
	flex-direction: row;
	gap: 10px;
}
