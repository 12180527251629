.button_container {
	display: flex;
	justify-content: center;
	margin-top: 24px;
}

.divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #111;

	margin-block: 24px;
}

.subtitle_row {
	display: flex;
	align-items: center;
	gap: 60px;
	text-wrap: nowrap;
}

.subtitle {
	font-size: 20px;
	font-weight: 500;
}

.subtitle_description {
	font-size: 14px;
	color: #888;
}

.field_row {
	display: flex;
	align-items: center;
	gap: 8px;
}

.field_type {
	width: 100px;
	font-size: 16px;
	text-wrap: nowrap;
}

.field_value {
	width: 100%;
}

.field_item {
	font-size: 14px;
	color: #333;
}

.field_option_item {
	width: 120px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
}

.field_option_item_small {
	width: 70px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-block: 16px;
}
