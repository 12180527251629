.table_row td {
	font-weight: 400;
	font-size: 14px;
}

.table_footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	margin-top: 24px;
}

.check_box {
	width: 60px;
}

.number {
	width: 30px;
}

.type {
	width: 100px;
}

.name {
	width: 250px;
	text-align: left;
}

.address {
	width: 320px;
	text-align: left;
}

.capacity {
	width: 100px;
}

.facilities {
	width: 100px;
}

.contact {
	width: 100px;
}
