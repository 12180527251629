.button {
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	padding-top: 7px;
	padding-bottom: 8px;

	padding-inline: 12px;

	border-radius: 5px;

	font-weight: 700;
	font-size: 18px;
	box-sizing: border-box;
}

.button:disabled {
	background-color: #999;
}

.button:has(svg) {
	padding-inline: 24px;
}

.black {
	background-color: #000;
	color: #fff;
}

.white {
	background-color: #fff;
	color: #000;

	border: 1px solid #333;
}

.prefix {
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
}

.suffix {
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
}

.normal {
	font-weight: 300;
}

.bold {
	font-weight: 700;
}
