.container {
	display: flex;
	flex-direction: row;
	gap: 24px;

	margin-top: 50px;
}

.left_section {
	width: 300px;
	margin-top: 50px;
}

.left_section h1 {
	font-size: 32px;
	font-weight: 300;

	margin-bottom: 10px;

	width: fit-content;

	border-bottom: 1px solid #000;
}

.left_section p {
	font-size: 18px;
	font-weight: 300;

	margin-bottom: 10px;
}

.right_section {
	flex: 1;
}

.sub_title {
	margin-bottom: 48px;
}

.title {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.title h1 {
	font-size: 32px;
	font-weight: 300;
}

.title span {
	font-size: 20px;
	font-weight: 700;

	text-decoration: underline;
}

.guide_content {
	display: flex;
	flex-direction: column;

	font-size: 32px;
	font-weight: 300;
	gap: 32px;
	margin-top: 140px;
}
