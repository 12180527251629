.my_competition_header {
	width: 100%;
	border-bottom: 3px solid #111;

	display: flex;

	padding-block: 20px;
}

.my_competition_header_title {
	color: #333;
	font-weight: 700;
	font-size: 20px;

	white-space: nowrap;

	margin-right: 50px;
}

.my_competition_header_select {
	width: 100%;

	margin-right: 20px;
}

.my_competition_header_button {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 5px 10px;

	border-radius: 5px;

	background-color: #111;
	color: #fff;

	font-weight: 500;
	font-size: 18px;
	line-height: 21.6px;

	white-space: nowrap;
	gap: 40px;
}
