@charset "UTF-8";

.visual img {max-width: 540px; min-width: calc(50% - 10px);}
.visual .img {display: flex; align-items: center; overflow: hidden;}
.visual .img > div {display: flex; justify-content: space-between; align-items: flex-start;}
.visual .img > div img {margin-right: 20px;}
.visual .img > div img:last-of-type {margin-right: 0;}
.visual .dots {text-align: center; margin-top: 30px;}
.visual .dots .dot {width: 12px; height: 12px; margin: 0 7px;}
/* .visual img {max-width: 540px; min-width: calc(50% - 10px);} */

.schedule {padding: 22px 0 50px; border-bottom: 0.5px dashed #111;} 
.schedule .tx_tit01 {font-size: 1.2rem; font-weight: 350; letter-spacing: -0.03em;}
.schedule .sec01 {display: flex; align-items: flex-start;}
.schedule .list {font-size: 2.6rem; font-weight: 100; letter-spacing: -0.04em; line-height: 35px; padding-right: 40px; width: 47.5%; }
.schedule .list .event {max-height: 70px; overflow: hidden; position: relative; padding-right: 25px; transition: max-height .7s ease; -webkit-transition: max-height .7s ease; word-break: keep-all;}
.schedule .list .event.open {height: auto; max-height: 700px; transition: max-height .7s ease; -webkit-transition: max-height .7s ease;}
.schedule .list b {font-weight: 700;}
.schedule .list .btn_plus {font-size: 3.6rem; font-weight: 700;position: absolute; right: 0; bottom: 5px;}
.schedule .list .btn_plus::after {content: "+"; display: inline-block; clear: both; width: 25px; height: 35px; text-align: center;}
.schedule .list .event.open .btn_plus::after {content: "-";}

.schedule .login {display: flex; flex-direction: row; align-items: center; font-size: 1.8rem; font-weight: 350; letter-spacing: -0.03em; line-height: 27px; margin-top: 35px;}
.schedule .login .btn_login {background: #111; color: #fff; width: 80px; height: 80px; border-radius: 40px; font-size: 1.5rem; font-weight: 700; letter-spacing: -0.03em; text-align: center; display: flex; align-items: center; justify-content: center; margin-right: 16px;}
.schedule .login .btn_membership {font-weight: 700; border-bottom: 1px solid #111;}

.schedule.type1 {border-bottom: none; padding: 22px 0 0;}
.schedule.type1 .list {width: 100%; padding-right: 0;}

/* notice */
.notice {margin-top: 73px; display: flex; align-items: flex-start; justify-content: space-between;}
.notice > div {width: 50%;}
.notice .list {padding-right: 56px;}
.notice .list .title {font-size: 2.6rem; font-weight: 350; letter-spacing: -0.06em; margin-bottom: 6px;}
.notice .list ul li {font-size: 1.6rem; font-weight: 400; color: #333; padding: 7px 0; display: flex; justify-content: space-between; align-items: center;}
.notice .list ul li p {overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 85%;}
.notice .list ul li span {font-size: 1.2rem; color: #777; text-align: center; padding-left: 10px;}

.woog_me {padding-left: 10px;}
.woog_me .tx_type01 {font-size: 2.2rem; font-weight: 300; letter-spacing: -0.03em; line-height: 30px;}
.woog_open {display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-top: 10px;}
.woog_open a {font-size: 2.2rem; letter-spacing: -0.05em; font-weight: 300; display: block; background: url(../images/common/ico_trophy.svg) no-repeat 15px center #333; color: #fff; min-width: 260px; padding: 8px 15px 8px 60px; border-radius: 30px; position: relative;}
.woog_open a::after {content: ""; display: inline-block; clear: both; background: url(../images/common/ico_arrow_w.svg) no-repeat 0 0; width: 8px; height: 13px; position: absolute; top: calc(50% - 8px); right: 15px} 
.woog_open p {font-size: 1.1rem; line-height: 16px; letter-spacing: -0.02em; font-weight: 300; color: #000; margin-left: 20px; position: relative; padding-left: 48px;}
.woog_open p::before {content: ""; display: block; clear: both; background: url(../images/common/ico_profile2.svg) no-repeat left center; width: 42px; height: 31px; position: absolute; left: 0; top: 0;}

/******************** Search  ********************/
.contents.search .schedule.type1 {margin-top: 100px; padding-top: 0;}
.contents.search .schedule .list .event {max-height: 35px;}
.contents.search .schedule .list .event.open {max-height: 500px;}
.contents.search .slide_list {margin-top: 100px;}

.search_section {display: flex; justify-content: flex-start; align-items: center; margin-top: 50px;}
.search_section .search_title {flex-grow: 1; width: 20%; max-width: 340px; align-self: flex-start;}
.search_section .search_contents {flex-grow: 2; width: 80%; max-width: 760px;}
.search_contents .search_box {margin-top: 115px;}
.search_contents .search_box select {height: 28px; width: 95px;}
.search_contents .search_box input {background: #fff; border: 1px solid #aaa; height: 28px; font-size: 1.2rem; width: 290px; padding: 0 5px;}
.search_contents .search_box .btn_type3 {min-width: 50px;}

.search_section.list {align-items: flex-start;}
.search_section.list .search_contents .search_box {margin-top: 11px;}
.search_section.list .tx_result {font-size: 2.0rem; font-weight: 500; margin-top: 35px;}
.search_section.list .search_list {margin-top: 46px; border-bottom: 1px dashed #111; padding-bottom: 22px;}
.search_section.list .search_list + .search_list {margin-top: 34px;}
.search_section.list .search_list .tit {font-size: 2.0rem; font-weight: 400; letter-spacing: -0.03em; color: #333; margin-bottom: 15px;}
.search_section.list .search_list ul li {font-size: 1.6rem; font-weight: 500; letter-spacing: -0.03em; line-height: 1.5; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; cursor: pointer; padding: 8px 0;}
.search_section.list .search_list ul .end {color: #555;}

.search_list.type1 ul li, .search_list.type2 ul li {display: flex; justify-content: flex-start; align-items: center;}
.search_list.type1 ul li b, .search_list.type2 ul li b {flex-grow: 2; width: 40%; max-width: 320px; padding-right: 10px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.search_list.type1 ul li span {flex-grow: 0; width: 45%; padding-right: 5px; overflow: hidden;}
.search_list.type1 ul li span + span {width: 15%;}

.search_list.type2 ul li b {width: 80%; max-width: 610px;}
.search_list.type2 ul li span {font-size: 1.4rem; font-weight: 400; flex-grow: 0; text-align: center;}
.search_list.type2 ul li span:last-of-type {color: #333; width: 5%;}
.search_list.type2 ul li .date {color: #aaa; width: 15%;}

/******************** //Search  ********************/

/* footer */
footer {margin-top: 78px;}

@media (min-width: 769px) and (max-width: 1024px) {
  .woog_open {flex-direction: column; align-items: flex-start;}
  .woog_open p {margin: 15px 0 0 15px;}
  .search_section.list .search_contents .search_box {margin-top: 6px;}
  .search_section.list .search_list {margin-top: 40px;}
} 